import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col } from 'styled-bootstrap-grid';
import Container from 'components/shared/Container';
import LazyImage from 'components/shared/LazyImage';
import { HeadingFour, Text } from 'components/shared/Typography';

const Wrapper = styled.section`
  margin: 30px 0 0 0;
  position: relative;
  & ${Col} {
    margin-left: auto;
  }
`;
const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 70vw;
  @media screen and (max-width: 992px) {
    position: relative;
    width: calc(100vw - 40px);
    height: calc(100vw - 40px);
  }
`;
const Content = styled.article`
  padding: 160px 0 160px 50px;
  background: ${({ theme }) => theme.colors.light};
  & ${HeadingFour} {
    margin: 0 0 40px 0;
  }
  @media screen and (max-width: 992px) {
    padding: 0;
    & ${HeadingFour} {
      margin: 40px 0 0 0;
    }
  }
`;
const List = styled.ol`
  @media screen and (max-width: 992px) {
    margin: 20px 0 50px 0;
  }
`;
const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
`;
const ListIcon = styled.p`
  margin: 0 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.greenVariant};
`;
const ListContent = styled(Text)`
  width: calc(100% - 50px);
  font-weight: 600;
`;

const Process = ({ photo, list }) => {
  return (
    <Wrapper>
      <Container noRelative>
        <Background>
          <LazyImage src={photo} cover alt="" />
        </Background>
        <Row>
          <Col lg={4}>
            <Content>
              <HeadingFour>Proces współpracy</HeadingFour>
              {list && (
                <List>
                  {list.map((item, index) => (
                    <ListItem key={index}>
                      <ListIcon>{index + 1}</ListIcon>
                      <ListContent>{item.text}</ListContent>
                    </ListItem>
                  ))}
                </List>
              )}
            </Content>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

Process.propTypes = {
  photo: PropTypes.string.isRequired,
  list: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Process;
