import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from 'components/shared/SEO';
import HeaderMini from 'components/Header/HeaderMini';
import Process from 'components/Process/Process';
import Offer from 'components/Offer/Offer';

const ServicesPage = ({ data }) => {
  const PAGE = data.wpPage.PageOffer;

  const offerList = PAGE.offerList.map(
    ({ offerListIcon, offerListHeading, offerListText, offerListImage }) => ({
      icon: offerListIcon.mediaItemUrl,
      image: offerListImage
        ? offerListImage.localFile.childImageSharp.gatsbyImageData
        : null,
      heading: offerListHeading,
      text: offerListText,
    })
  );

  const processList = PAGE.offerProcessList.map(({ offerProcessListText }) => ({
    text: offerProcessListText,
  }));

  return (
    <>
      <SEO title="Usługi" />
      <HeaderMini
        spacing
        maxWidth="540px"
        subheading={PAGE.offerBannerSubheading}
        heading={PAGE.offerBannerHeading}
        text={PAGE.offerBannerText}
      />
      <Offer list={offerList} />
      <Process
        list={processList}
        photo={PAGE.offerProcessImage.localFile.childImageSharp.gatsbyImageData}
      />
    </>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query queryPageOffer {
    wpPage(slug: { eq: "uslugi" }) {
      PageOffer {
        offerProcessImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: BLURRED
                formats: [WEBP]
              )
            }
          }
        }
        offerProcessTitle
        offerProcessList {
          offerProcessListText
        }
        offerBannerText
        offerBannerHeading
        offerBannerSubheading
        offerBannerImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: BLURRED
                formats: [WEBP]
              )
            }
          }
        }
        offerList {
          offerListIcon {
            mediaItemUrl
          }
          offerListText
          offerListHeading
          offerListImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: BLURRED
                  formats: [WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default ServicesPage;
