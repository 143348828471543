import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col } from 'styled-bootstrap-grid';
import Container from 'components/shared/Container';
import { HeadingFour, TextLarge } from 'components/shared/Typography';
import Icon from 'components/shared/Icon';
import LazyImage from 'components/shared/LazyImage';

const Wrapper = styled.section`
  position: relative;
  padding: 0;
  margin: -40px 0 0 0;
  z-index: 9;
  & ${Col} {
    margin-bottom: 30px;
  }
`;

const TileOffer = styled.article`
  margin: 0;
  background-color: #fff;
  height: 100%;
  padding: ${({ spacing }) => (spacing ? '50px' : '0')};
  min-height: 280px;
  display: flex;
  @media screen and (max-width: 992px) {
    padding: ${({ spacing }) => (spacing ? '40px' : '0')};
    flex-direction: column;
  }
`;
const TileOfferIcon = styled.div`
  min-width: 120px;
  @media screen and (max-width: 992px) {
    min-width: 100%;
    margin: 0 0 15px 0;
  }
`;
const TileOfferContent = styled.div`
  padding: ${({ spacing }) => (spacing ? '50px' : '0')};
  & ${TextLarge} {
    margin: 15px 0 0 0;
  }
  @media screen and (max-width: 992px) {
    padding: ${({ spacing }) => (spacing ? '40px' : '0')};
  }
`;
const TileOfferPhoto = styled.div`
  width: 50%;
  min-width: 50%;
  @media screen and (max-width: 992px) {
    width: calc(100vw - 40px);
    height: calc(100vw - 40px);
  }
`;

const Offer = ({ list }) => {
  return (
    <Wrapper>
      <Container>
        {list && (
          <Row>
            {list.map((item, index) =>
              !item.image ? (
                <Col key={index} lg={6}>
                  <TileOffer spacing key={index} image>
                    <TileOfferIcon>
                      <Icon src={item.icon} size={70} alt="" />
                    </TileOfferIcon>
                    <TileOfferContent>
                      <HeadingFour>{item.heading}</HeadingFour>
                      <TextLarge>{item.text}</TextLarge>
                    </TileOfferContent>
                  </TileOffer>
                </Col>
              ) : (
                <Col key={index} lg={12}>
                  <TileOffer key={index}>
                    <TileOfferPhoto>
                      <LazyImage src={item.image} cover alt="" />
                    </TileOfferPhoto>
                    <TileOfferContent spacing>
                      <HeadingFour>{item.heading}</HeadingFour>
                      <TextLarge>{item.text}</TextLarge>
                    </TileOfferContent>
                  </TileOffer>
                </Col>
              )
            )}
          </Row>
        )}
      </Container>
    </Wrapper>
  );
};

Offer.propTypes = {
  list: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default Offer;
